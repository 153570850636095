var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-title',[_c('strong'),_vm._v(" Stock Moves "),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col'),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"headers":_vm.headers,"items":_vm.assignments,"item-key":"id","multi-sort":"","search":_vm.search,"show-select":""},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.toWarehouse ? 'Move' : 'Assignment')+" ")])])]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getFrom(item))+" ")])])]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getTo(item))+" ")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"})]}}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1),_c('ConfirmDialog',{ref:"confirmDelete"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <strong>
          </strong> Stock Moves
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details

          ></v-text-field>
        </v-card-title>
<v-col></v-col>
        <v-data-table
            :headers="headers"
            :items="assignments"
            item-key="id"
            class="elevation-1 table-one"
            multi-sort
            :search="search"
            v-model="selectedRows"
            show-select
        >
          <template v-slot:item.type="{item}">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">
                {{ item.toWarehouse ? 'Move' : 'Assignment' }}
              </p>
            </div>
          </template>
          <template v-slot:item.from="{item}">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">
                {{ getFrom(item) }}
              </p>
            </div>
          </template>
          <template v-slot:item.to="{item}">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">
                {{ getTo(item) }}
              </p>
            </div>
          </template>
          <template v-slot:item.action="{item}">
            <div class="d-flex">

            </div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>

    <ConfirmDialog ref="confirmDelete" />
  </v-row>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import { saveAs } from 'file-saver';


export default {
  name: "Assignments",
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      // zoom: 18,
      // center: [51.48819, -0.31524],
      // bounds: null,
      // editing: {},
      // sensors: [],
      repLabelCustomer: '',
      repLabelSite: '',
      repLabelDate: '',
      search: '',
      items: [],
      selectedRows: [],
      headers: [
        {
          text: 'Type',
          sortable: false,
          value: 'type',
          align: 'left',
        },
        {
          text: 'Description',
          sortable: true,
          value: 'description',
          align: 'left',
        },
        {
          text: 'Serial',
          sortable: true,
          value: 'device.serial',
          align: 'left',
        },
        {
          text: 'Type',
          sortable: true,
          value: 'device.device_type',
          align: 'left',
        },
        {
          text: 'From',
          sortable: true,
          value: 'from',
          align: 'left',
        },
        {
          text: 'To',
          sortable: true,
          value: 'to',
          align: 'middle',
        },
        {
          text: 'Date',
          sortable: true,
          value: 'createdAt',
          align: 'middle',
        },
        {
          text: 'Actions',
          value: 'action',
        }
      ],
    }
  },
  computed: {
    ...mapState('assignments', {loadingAssignments : 'isFindPending'}),
    ...mapGetters('assignments', {findAssignmentsInStore : 'find'}),

    assignments() {
      return this.findAssignmentsInStore({ query: {assignment_status: { $ne: 'stock' }, archived: { $ne: 1 }}}).data || [];
    },
    selectedAssignmentIds() {
      return this.selectedRows.map(a => a.id);
    }
  },
  methods: {
    ...mapActions('assignments', { findAssignments: 'find' }),
    getFrom(item){
      if(item.toWarehouse) {
        return item.fromWarehouse.name
      } else {
        return item.fromPartner.name
      }
    },
    getTo(item) {
      if(item.toWarehouse) {
        return item.toWarehouse.name
      } else {
        return item.toPartner.name
      }
    },
    deleteItem(id) {
      this.$store.dispatch('assignments/remove', id)
    },
    async delRecord(id) {
      if (
          await this.$refs.confirmDelete.open(
              "Confirm",
              "Are you sure you want to delete this record?"
          )
      ) {
        this.deleteItem(id);
      }
    },
    viewAssignment(id) {
      this.$router.push('/app/assignmentview/'+id)
    },
  },
  mounted() {
    this.findAssignments();
    if (localStorage.assignmentsIndexSearch) {
      this.search = localStorage.assignmentsIndexSearch;
    }
  },
  watch: {
    search(newSearchTerm) {
      localStorage.assignmentsIndexSearch = newSearchTerm;
    }
  },
}
</script>

<style scoped>

</style>
